import ApiClient from '../apiClient';
import Config from '../../config';
import { defaultFetcher} from '../utils/fetcher';
const defaultClient = new ApiClient(Config.API_URL, defaultFetcher);

function contactSendMessage(data) {
  console.log('contactSendMessage:', data)
  const query = 'mutation{notify(event:{websiteId:"'+Config.WEBSITE_ID+'",sender:{email:"'+data['email']+'"},title:"'+data['name']+'",description:"'+data['message']+'"}){success status notificationId message}}';
  return defaultClient.post('/', {query: query});
}

export default {
  contactSendMessage,
};
