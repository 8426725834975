import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import routing from './routing/reducer';
import common from './../store/common/reducer';
import contact from './../store/contact/reducer';

const store = createStore(
  combineReducers({
    routing,
    common,
    contact,
  }),
  {},
  compose(applyMiddleware(thunk))
);

export default store;
