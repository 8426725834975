import React from 'react'
import './style.css'

import { validate, isEmpty } from '../../../helpers/validation';
import contactApi from '../../../apis/contact';

export default class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {}
        }
    }

    handleChange = (e, field) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    onSubmit = () => {
        if (this.checkValidate()) {
            console.log('message: ', this.state.fields)
            this.handSendMessage(this.state.fields);
        }
    }

    handSendMessage = async (message) => {
        let result = await contactApi.contactSendMessage(message);
        console.log('result: ', result)
        if (result.errors && !isEmpty(result.errors)) {
            alert(result.errors[0].message)
            return;
        }

        this.resetForm();
        if (result.data && result.data.notify) {
            alert('Gửi thành công!');
            return;
        }

        alert('Lỗi kết nối, vui lòng thử lại!')
    }

    resetForm() {
        this.setState({
            fields: {},
            errors: {}
        });
    }

    checkValidate = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        const nameError = validate('name', fields.name);
        if (nameError) {
            errors["name"] = nameError;
            formIsValid = false;
        }

        const emailError = validate('email', fields.email);
        if (emailError) {
            errors["email"] = emailError;
            formIsValid = false;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    renderError = (errorField) => {
        if (errorField) {
            return (
                <span className="message-error">{errorField}</span>
            )
        }
    }

    render() {
        var fields = this.state.fields;
        var errors = this.state.errors;
        return (
            <div className="container-fluid block" id="contact-us">
                <div className="row text-center ">
                    <div className="col-12 margin">
                        <h1 className="header-h1">LIÊN HỆ VỚI CHÚNG TÔI</h1>
                        <h2 className="header-h2">Để sở hữu ngay gói ưu đãi này</h2>
                    </div>
                    <div className="col-12">
                        <span>Tên của bạn</span>
                        <input
                            type="text"
                            className="form-control"
                            onChange={(e) => this.handleChange(e, "name")}
                            value={fields.name ? fields.name : ''}
                            refs="name"
                        />
                        {this.renderError(errors["name"])}
                    </div>
                    <div className="col-12">
                        <span>Email hoặc số điện thoại</span>
                        <input
                            type="text"
                            className="form-control"
                            onChange={(e) => this.handleChange(e, "email")}
                            value={fields.email ? fields.email : ''}
                            refs="email"
                        />
                        {this.renderError(errors["email"])}
                    </div>
                    <div className="col-12">
                        <button className="btn-submit" onClick={this.onSubmit}>
                            Gửi thông tin
                            <i className="fas fa-arrow-right" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}