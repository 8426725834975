export const SET_TOKEN               = '/auth/setToken';
export const SET_ME                  = '/auth/setMe';
export const LOG_OUT                 = '/auth/logOut';
export const SEND_EMAIL_SUCCESS      = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAIL         = 'SEND_EMAIL_FAIL';
export const SEND_RESEND_OTP_SUCCESS = "SEND_RESEND_OTP_SUCCESS";
export const SEND_RESEND_OTP_FAIL    = "SEND_RESEND_OTP_FAIL";
export const GET_ME                  = "GET_ME";


const initialState = {
  me          : [],
  username    : '',
  password    : '',
  token       : '',
  email       : '',
  phone       : '',
  roles       : [],
  id          : '',
  name        : '',
  sendSuccess : false,
  sendFail    : false,
  resend_otp  : false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOG_OUT:
      return {
        ...state,
        token: '',
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.data.token,
        username : action.data.username,
        password : action.data.password
      };
    case SET_ME:
      return {
        ...state,
        id : action.id,
        name : action.name
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        sendSuccess : true
      };
    case SEND_EMAIL_FAIL:
      return {
        ...state,
        sendFail : true
      };
    
    case SEND_RESEND_OTP_SUCCESS:
      return {
        ...state,
        resend_otp : true,
      };
    
    case SEND_RESEND_OTP_FAIL:
        return {
          ...state,
        };

    case GET_ME:
      return {
        ...state,
        me : action.me.data.account,
      }
    default:
      return state;
  }
};
