import React from 'react'
import About from './about'
import Reason from './reason'
import Solution from './solution'
import Product from './product'
import Incentives from './incentives'
import ContactUs from './contact-us'
import Footer from '../../components/footer'
import './style.css'

export default class Home extends React.Component
{
    render() {
        return (
            <div>
                <About />
                <Reason />
                <div className="wiooh-bg-1">
                    <img src="/image/svg/bg2.svg" alt="bg"/>
                </div>
                <Solution />
                <Product />
                <div className="wiooh-bg-1">
                    <img src="/image/svg/bg1.svg" alt="bg"/>
                </div>
                <Incentives />
                <ContactUs />
                <Footer />
            </div>
        )
    }
}