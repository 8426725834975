import querystring from 'querystring';

export default class ApiClient {
  constructor(host, fetcher) {
    this._api_host = host;
    this._fetcher = fetcher;
  }

  get(endpoint, params) {
    const query = params ? '?' + querystring.stringify(params) : '';
    return this._fetcher(this._api_host + endpoint + query);
  }

  getBody(endpoint, data) {
    return this._fetcher(this._api_host + endpoint, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }

  post(endpoint, data) {
    return this._fetcher(this._api_host + endpoint, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }

  put(endpoint, data) {
    return this._fetcher(this._api_host + endpoint, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }

  patch(endpoint, data) {
    return this._fetcher(this._api_host + endpoint, {
      method: 'PATCH',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }

  delete(endpoint) {
    return this._fetcher(this._api_host + endpoint, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  /* istanbul ignore next: it's very hard to test */
  uploadFile(endpoint, params, data, onProgress, headers) {
    const query = params ? '?' + querystring.stringify(params) : '';
    const url = this._api_host + endpoint + query;
    return this._fetcher(
      url,
      {
        method: 'POST',
        mode: 'cors',
        headers,
        body: data,
      },
      onProgress
    );
  }

  /* istanbul ignore next: it's very hard to test */
  downloadFile(endpoint, params, data, onProgress) {
    const query = params ? '?' + querystring.stringify(params) : '';
    const url = this._api_host + endpoint + query;
    return this._fetcher(
      url,
      {
        method: 'GET',
        headers: {
          'Cache-Control': 'no cache',
        },
        body: data,
      },
      onProgress
    );
  }
}
