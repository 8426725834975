import React from 'react'
import ItemSoluction from './item'
import './style.css'

export default class Solution extends React.Component
{
    render() {
        return (
            <div className="container block" id="solution">
                <div className="row">
                    <div className="col-12 margin">
                        <h1 className="header-h1">Giải pháp</h1>
                        <h2 className="header-h2">của chúng tôi</h2>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <ItemSoluction
                            src   = "/image/svg/tuvan.svg"
                            title = "Tư vấn"
                            text  = "Hiểu khách hàng và định hình phong cách thương hiệu"
                        />
                    </div>

                    <div className="col-sm-6 col-lg-3">
                        <ItemSoluction
                            src   = "/image/svg/thietkeweb.svg"
                            title = "Thiết kế"
                            text  = "Thiết kế các mẫu sản phẩm phù hợp với thương hiệu và hình ảnh khách hàng"
                        />
                    </div>

                    <div className="col-sm-6 col-lg-3">
                        <ItemSoluction
                            src   = "/image/svg/thicong.svg"
                            title = "Thi công"
                            text  = "Xây dựng website và biến thiết kế mẫu thành sản phẩm thật"
                        />
                    </div>

                    <div className="col-sm-6 col-lg-3">
                        <ItemSoluction
                            src   = "/image/svg/hoanthien.svg"
                            title = "Hoàn thiện"
                            text  = "Chỉnh sửa, hoàn thiện nội dung và hướng dẫn khách hàng sử dụng"
                        />
                    </div>
                </div>
            </div>
        )
    }
}