import React from 'react'
import './style.css'

export default class About extends React.Component
{
    render() {
        return(
            <div className="container-fluid block" id="about">
                <div className="row">
                    <div className="col-sm-5"></div>
                    <div className="col-sm-7 ">
                        <div className="content">
                            <h4> Hãy để chúng tôi, </h4><br/>
                            <h1 className="content-header-1">NÂNG TẦM </h1><br />
                            <h1 className="content-header-2">THƯƠNG HIỆU</h1><br />
                            <span>
                                <h4 className="text-1"> CHO ĐAM MÊ CỦA BẠN!</h4>
                                <hr />
                                <h5 className="text-2">"Bắt đầu phát triển sự nghiệp của bạn từ việc <br />
                                xây dựng thượng hiệu với một website mang tên bạn!"</h5>
                            </span>
                        </div> 
                    </div>
                </div>
            </div>
        )
    }
}