import React from 'react'
import './style.css'

export default class Footer extends React.Component
{
    render() {
        return (
            <div className="container-fluid" id="footer">
                <div className="row">
                    <div className="col-sm-6 col-md-3 logo-wiooh">
                        <a href="https://wiooh.com">
                            <img src="/image/svg/logo.svg" alt="logo" />
                        </a> 
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <h2>Liên hệ với chúng tôi</h2>
                        <h4>
                            <i className="fas fa-map-marker-alt"></i>
                                <a href="https://g.page/wiooh?share" className="location" target="_blank">Xem vị trí</a>
                            <br />
                            <i className="fas fa-phone-alt"></i>
                                <a href="tel:0123456789">0123 456 789</a>
                            <br />
                            <i className="far fa-envelope"></i>
                                
                                <a href="mailto:info@wiooh.com">info@wiooh.com</a>
                            <br />
                            <i className="fas fa-globe"></i>
                            <a href="https://wiooh.com">wiooh.com</a>
                        </h4>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <h2>Công ty</h2>
                        <h4>
                            <a href="https://wiooh.com">Về chúng tôi</a> <br />
                            <a href="https://wiooh.com">Sản phẩm và dịch vụ </a><br />
                            <a href="https://wiooh.com">Liên hệ</a> 
                        </h4>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <h2>Kết nối với chúng tôi</h2>
                        <h4>
                            <a href="https://www.facebook.com/Wioohcom-107608334325475" target="_blank" title="Click để xem bản đồ ">
                                <img src="image/svg/icon-facebook.svg" className="icon-linkin-1" alt="facebook icon"/>
                            </a>
                            <a href="https://www.linkedin.com/company/wiooh/" target="_blank">
                                <img src="image/svg/linkin-icon.svg" className="icon-linkin-2" alt="linkin icon"/>
                            </a>
                            <br />
                            <a href="https://wiooh.com">@ 2020 Wiooh </a><br />
                            <a href="https://wiooh.com">All right reserved</a>
                        </h4>
                    </div>
                </div>
            </div>
        )
    }
}