import React from 'react'

export default class ItemReason extends React.Component {

    renderHtmlBoxText() {
        if(this.props.text) {
            var str = this.props.str ? this.props.str : "!@#$";
            return this.props.text.replace(str , `<b>${str}<span/></b>`);
        }
    }

    render () {
        return (
            <div className="box-item">
                <img src={this.props.src} alt="tai sao can mot website wiooh"/>
                <div className="box-item-text">
                    <div className="text-center">
                        <span dangerouslySetInnerHTML={{__html: this.renderHtmlBoxText()}}/>
                    </div>
                </div>
            </div>
        )
    }
}