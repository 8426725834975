const validation = {
  email: {
    presence: {
      message: '^Vui lòng nhập địa chỉ email',
    },
    email: {
      message: '^Email không hợp lệ',
    },
  },

  name: {
    presence: {
      message: '^Vui lòng nhập Họ tên',
    },
  },

  phoneNumber: {
    presence: {
      message: '^Vui lòng nhập số điện thoại',
    },
    format: {
      pattern: '[0-9]+',
      flags: 'i',
      message: '^Số điện thoại chỉ nhập số',
    },
    length: {
      minimum: 7,
      maximum: 11,
      message: '^Điện thoại phải có ít nhất 7 số, nhiều nhất 12 số',
    },
  },

  password: {
    presence: {
      message: '^Vui lòng nhập mật khẩu',
    },
    length: {
      minimum: 6,
      message: '^Mật khẩu của bạn phải có ít nhất 6 ký tự',
    },
  },

  otp: {
    presence: {
      message: '^Vui lòng nhập mã OTP',
    },
    length: {
      minimum: 4,
      message: '^Mã OTP phải nhập 4 số',
    },
  },
};

export default validation;
