import React from 'react'

export default class ItemSolution extends React.Component
{
    render() {
        return (
            <div className="box-item">
                <div className="box-item-icon">
                    <div className="circle-icon">
                        <img src={this.props.src} alt="wiooh icon solution "/>
                    </div>
                </div>
                <div className="box-item-text text-center">
                    <h3><span/>{this.props.title}</h3>
                    <h4>{this.props.text}</h4>
                </div>
            </div>
        )
    }
}