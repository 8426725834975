import Config from '../../config';

export default class JWTAuth {
  constructor() {
    this._token = null;
  }

  setTokenTemp(token) {
    this._token = token;
  }

  getTokenTemp() {
    return this._token;
  }

  setToken() {
    localStorage.setItem('access_token',this._token);
  }

  setAccessToken(token) {
    localStorage.setItem('access_token',token);
  }

  getToken() {
    if(localStorage.getItem('access_token')) {
      return localStorage.getItem('access_token');
    }
    return this._token;
  }

  checkToken() {
    if(localStorage.getItem('access_token')) {
      return true;
    }
    return false;
  }

  removeToken() {
    localStorage.removeItem('access_token');
  }

  appendToken(opts = {}) {
    const optHeaders = opts.headers || {};
    return {
      ...opts,
      headers: {
        ...optHeaders,
        //Authorization: `Bearer ${this._token}`,
        Authorization: `${Config.TOKEN}`,
      },
    };
  }

  getFetchFn() {
    return (url, options) => fetch(url, this.appendToken(options));
  }
}
