import validationjs from 'validate.js';
import config from './config';

export function validate(fieldName, value) {
  value = value === '' ? null : value;
  var formValues = {};
  formValues[fieldName] = value;

  var formFields = {};
  formFields[fieldName] = config[fieldName];

  const result = validationjs(formValues, formFields);
  if (result) {
    return result[fieldName][0];
  }

  return null;
}

export function isEmpty(e) {
  switch (e) {
    case '':
    case 0:
    case '0':
    case null:
    case false:
    case typeof e == 'undefined':
      return true;
    default:
      return false;
  }
}
