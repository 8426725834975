export const THROW_ERROR = '/error';
export const DROP_ERROR = 'DROP_ERROR';

const initialState = {};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case THROW_ERROR:
      return {
        ...state,
        ...action.errors,
      };
    
    case DROP_ERROR:
      return {
      }
    default:
      return state;
  }
};
