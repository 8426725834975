import React from 'react'
import ItemReason from './item'
import './style.css'
export default class Reason extends React.Component
{
    render() {
        return (
            <div className="container block" id="reason">
                <div className="row">
                    <div className="col-12 text-center margin">
                        <h2 className="header-h2">Tại sao bạn cần một</h2>
                        <h1 className="header-h1">WEBSITE?</h1>
                    </div>
                    <div className="col-sm-4">
                        <ItemReason
                            src  = "/image/about1.png"
                            text = "Tạo dựng profile chuyên nghiệp ấn tượng, phô diễn tài năng với khán giả"
                            str  = "chuyên nghiệp"
                        />
                    </div>
                    <div className="col-sm-4">
                        <ItemReason
                            src  = "/image/about2.png"
                            text = "Xuất hiện và dễ dàng được tìm thấy trên Goole - bộ máy tìm kiếm lớn nhất thế giới"
                            str  = "Goole"
                        />
                    </div>
                    <div className="col-sm-4">
                        <ItemReason
                            src  = "/image/about3.png"
                            text = "Công cụ quảng bá hình ảnh cá nhân 24/7/365 vượt phạm vi quốc gia"
                            str  = "quảng bá"
                        />
                    </div>
                </div>
            </div>
        )
    }
}