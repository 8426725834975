const API_URL = 'https://graphql.wiooh.com';
const TOKEN_KEY = 'token';
const WEBSITE_ID = '5f16958cbe05bc00137f1742';
const TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZjFhNGU4MjY0OTcxYTAwMTMxNGUwNDIiLCJteVNpdGVzIjpbXSwibm90aWZpY2F0aW9ucyI6W3sidHlwZSI6IiIsImxpbmtUbyI6IiIsInRpdGxlIjoiIiwiZGVzY3JpcHRpb24iOiIiLCJpbWFnZSI6IiJ9XSwiZnVsbG5hbWUiOiJBZG1pbiBXaW9vaCIsImF2YXRhciI6IiIsImVtYWlsIjoiYWRtaW5Ad2lvb2guY29tIiwicGhvbmUiOiIwOTg3NjU0MzIxIiwibGFuZ3VhZ2UiOiIiLCJpZCI6IjVmMWE0ZTgyNjQ5NzFhMDAxMzE0ZTA0MiIsImlzQWN0aXZlIjpmYWxzZSwiaWF0IjoxNTk1NTY2NzUzfQ.j4ZdGtMsWBPUQ6o_iy6L-LW8VgU4kBVDQ8jeuoJ9XY8';
export default {
    API_URL,
    WEBSITE_ID,
    TOKEN_KEY,
    TOKEN
};
