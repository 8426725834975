export const CONTACT_SEND_MESSAGE = 'CONTACT_SEND_MESSAGE';
export const CONTACT_ERROR    = 'CONTACT_ERROR';

const initialState = {
    resultSend: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CONTACT_SEND_MESSAGE:
      return {
        ...state,
        resultSend: action.data,
      };
    case CONTACT_ERROR :
        return {
            ...state,
            error : true,
            resultSend: action.data,
        }
    default:
      return state;
  }
};
