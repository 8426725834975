import React from 'react'
import './style.css'

export default class Incentives extends React.Component
{
    render() {
        return (
            <div className="container-fluid block" id="incentives" >
                <div className="row">
                    <div className="col-12 text-center margin">
                        <h1 className="header-h1">ưu đãi đặc biệt</h1>
                        <h2 className="header-h2"> dành cho nghệ sĩ</h2>
                    </div>
                    <div className="col-12">
                        <div className="col-12 box-incentives-text">
                            <div className="col-12 bg-df"></div>
                            <div className="col-12 box-text text-center">
                                <h4>Chỉ từ</h4>
                                <h1>3.000.000</h1>
                            </div>
                            <div className="col-12">
                                <div className="row container box-text-2">
                                    <div className="col-4 item-text">
                                    <div>
                                        <span>
                                            <h2>Thiết kế <br />website</h2>
                                            <h3>phù hợp với phong cách cá nhân</h3>
                                        </span>
                                        </div>
                                    </div>
                                    <div className="col-4 item-text">
                                        <div>
                                            <span>
                                                <h4>Sở hửu ngay</h4>
                                                <h2>Website mang<br />tên bạn</h2>
                                                <h3>được thiết kế chuyên nghiệp theo phong cách cá nhân</h3>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-4 item-text">
                                        <div>
                                        <span>
                                            <h4>Gói</h4>
                                            <h2>Chụp hình<br />nghệ thuật</h2>
                                            <h3>theo phong cách cá nhân tại Studio*</h3>
                                            <h5>*Số lượng có hạn</h5>
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}