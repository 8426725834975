import {HOME_SCREEN} from './index';

export const SCREEN_CHANGE = '/routing/screen_change';
export const ROUT_CHANGE   = 'ROUT_CHANGE'

const initialState = {
  curScreen   : HOME_SCREEN,
  history     : {
    curScreen   : HOME_SCREEN,
    history     : {},
  },
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SCREEN_CHANGE :
      return {
        ...state,
        curScreen : action.screen,
        history   : {...state},
      };
    default :
      return state;
  }
};
