import React from 'react'
import './style.css'

export default class ImgSlider extends React.Component
{
    constructor(props) {
        super(props)
        this.state = {
            active: 1,
            active_old : 0,
        }
    }


    classNameImage(index) {
        var active = this.props.active;
        var active_old = this.props.active_old;
        if(this.props.images) {
            active = active % this.props.images.length;
        }

        if(active > active_old) {
            if(active == (index)) {
                return "img-item active rightin" ;
            } else if(active_old == (index)) {
                return "img-item active rightout";
            } else {
                return "img-item";
            }
        } else {
            if(active == (index)) {
                return "img-item active leftin" ;
            } else if(active_old == (index)) {
                return "img-item active leftout";
            } else {
                return "img-item";
            }
        }
    }

    renderRowVideo() {
        if(this.props.images) {
            return this.props.images.map((item,index) => {
                return (
                    <div key={index+1} className={this.classNameImage(index)}>
                            <img src={item.src} alt="image"/>
                    </div>
                )
            })
        }
        return null;
    }


    render () {
        return (
            <div className="slider-1007">
                {this.renderRowVideo()}
            </div>
        )
    }
}