import store from '../../store';
import {LOG_OUT} from '../../store/auth/reducer';
import {THROW_ERROR} from '../../store/error/reducer';
import {LOGIN_SCREEN} from '../../store/routing';
import {push} from '../../store/routing/actions';
import {jwtAuth} from './fetcher';

export const logoutOnUnauthorized = (response) => {
  if (response.status === 401) {
    jwtAuth.removeToken();
    store.dispatch({type: LOG_OUT});
    store.dispatch(push(LOGIN_SCREEN));
  }
  return response;
};

export function rejectInvalidStatusCode({response, json}) {
  if (response.status >= 200 && response.status < 300) {
    return {response, json};
  }
  if (json.errors) {
    return throwErr(json.errors);
  }
  if (json.data && json.data.errors) {
    return throwErr(json.data);
  }
  throwErr({
    statusCode: response.status,
    message:
      json.message ||
      (json.data && json.data.message) ||
      `Server error: ${response.statusText}`,
  });
}

export async function toJson(response) {
  const responseText = await response.clone().text();
  if (responseText.length === 0) {
    return {
      response,
      json: {
        data: {},
      },
    };
  }
  try {
    const json = await response.json();
    return {response, json};
  } catch (_) {
    throwErr({
      statusCode: response.status,
      message: response.statusText,
    });
  }
}

export function getJson(res) {
  if(res && res.json) {
    return res.json;
  }
}

const throwErr = (errors) => {
  store.dispatch({
    type: THROW_ERROR,
    errors,
  });
};
