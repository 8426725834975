import React from 'react'
import ImgSlider from './imgSlider'
import './style.css'

export default class Product extends React.Component
{
    constructor(props){
        super(props)
        this.state = {
            active     : 0,
            active_old : 0,
            images     : [
                {src: "/image/sp1.png"},
                {src: "/image/sp1.png"},
                {src: "/image/sp1.png"},
                {src: "/image/sp1.png"},
                {src: "/image/sp1.png"},
                {src: "/image/sp1.png"},
            ]
        }
    }

    onBack = () => {
        var active = this.state.active -1;
        var active_old = this.state.active;
        if(active < 1) {
            active_old = this.state.images.length -1;
            active = this.state.images.length -2;
        }
        this.setState({
            active : active,
            active_old : active_old
        });
    }

    onNext = () => {
        var active = this.state.active + 1;
        var active_old = this.state.active;
        if(active >= this.state.images.length) {
            active_old = 0;
            active = 1;
        }
        this.setState({
            active : active,
            active_old : active_old
        });
    }

    render() {
        return (
            <div className="container block" id="product">
                <div className="row">
                    <div className="col-12 margin">
                        <h1 className="header-h1">Sản phẩm</h1>
                        <h2 className="header-h2">của chúng tôi</h2>
                    </div>
                    <div className="col-12">
                        <div className="box-img">
                            <ImgSlider 
                                images     = {this.state.images}
                                active     = {this.state.active}
                                active_old = {this.state.active_old}
                            />
                            <span className="btn-back">
                                <span>
                                    <button className="onright" onClick={this.onNext}><i className="fas fa-arrow-right"></i></button>
                                    <button className="onleft" onClick={this.onBack}><i className="fas fa-arrow-left"></i></button>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="col-12 text-center">
                        <button className="btn-default-1107"> Xem chi tiết <i className="fas fa-arrow-right"></i> </button>
                    </div>
                </div>
                
            </div>
        )
    }
}